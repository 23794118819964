
@font-face {
    font-family: 'Forgotten Futurist';
    src: url('../assets/fonts/ForgottenFuturistRg-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Forgotten Futurist';
    src: url('../assets/fonts/ForgottenFuturistRg-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

.forgotten {
    font-family: 'Forgotten Futurist', sans-serif;
    font-weight: normal; // You can specify other styles as needed
}

.forgotten-bold {
    font-family: 'Forgotten Futurist', sans-serif;
    font-weight: bold; // You can specify other styles as needed
}

:root {
    --Gradient-Primary-GRADIENT: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
}

.gradient-text {
    background: var(--Gradient-Primary-GRADIENT);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent; /* Fallback for browsers that don't support background-clip: text */
}

.f14 {
    font-size: 14px;
}

.f16 {
    font-size: 16px;
}
  