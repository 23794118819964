.icon-ns {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;

  &.w-120.h-130 {
    @include iconSize(120px, 130px);
  }
  &.w-130.h-130 {
    @include iconSize(130px, 130px);
  }

  &.w-20.h-20 {
    @include iconSize(20px, 20px);
  }
  &.w-20.h-4 {
    @include iconSize(20px, 4px);
  }
  &.w-12.h-12 {
    @include iconSize(12px, 12px);
  }
  &.w-16.h-16 {
    @include iconSize(16px, 16px);
  }
  &.w-18.h-18 {
    @include iconSize(18px, 18px);
  }
  &.w-20.h-28 {
    @include iconSize(20px, 28px);
  }
  &.w-30.h-20 {
    @include iconSize(30px, 20px);
  }

  &.w-22.h-22 {
    @include iconSize(22px, 22px);
  }

  &.w-24.h-24 {
    @include iconSize(24px, 24px);
  }

  &.w-28.h-28 {
    @include iconSize(28px, 28px);
  }
  &.w-32.h-32 {
    @include iconSize(32px, 32px);
  }
  &.w-36.h-36 {
    @include iconSize(36px, 36px);
  }

  &.w-40.h-40 {
    @include iconSize(40px, 40px);
  }
  &.w-240.h-240 {
    @include iconSize(240px, 240px);
  }
  &.w-46.h-46 {
    @include iconSize(46px, 46px);
  }
  &.w-66.h-66 {
    @include iconSize(66px, 66px);
  }
  &.w-100.h-100 {
    @include iconSize(100px, 100px);
  }

  &.w-14.h-14 {
    @include iconSize(14px, 14px);
  }
  &.w-16.h-16 {
    @include iconSize(16px, 16px);
  }
  &.w-16.h-7 {
    @include iconSize(16px, 7px);
  }
  &.w-12.h-22 {
    @include iconSize(12px, 22px);
  }

  &.w-38.h-38 {
    @include iconSize(38px, 38px);
  }

  &.ns-classicNews {
    @include getIcon(classicNews);
  }
  &.ns-topNews {
    @include getIcon(topNews);
  }
  &.ns-settingHand {
    @include getIcon(settingHand);
  }
  &.ns-testScience {
    @include getIcon(testScience);
  }
  &.ns-plusWhite {
    @include getIcon(plusWhite);
  }
  &.ns-minusBlack {
    @include getIcon(minusBlack);
  }
  &.ns-logoCircle {
    @include getIcon(logoCircle);
  }
  &.ns-playBgBlue {
    @include getIcon(playBgBlue);
  }
  &.ns-playBgGreen {
    @include getIcon(playBgGreen);
  }
  &.ns-playBgYellow {
    @include getIcon(playBgYellow);
  }
  &.ns-playBgGray {
    @include getIcon(playBgGray);
  }
  &.ns-playBgRed {
    @include getIcon(playBgRed);
  }
  &.ns-playBgCross {
    @include getIcon(playBgCross);
  }
  &.ns-pauseBgBlue {
    @include getIcon(pauseBgBlue);
  }
  &.ns-pauseBgGreen {
    @include getIcon(pauseBgGreen);
  }
  &.ns-backBtnBgBlue {
    @include getIcon(backBtnBgBlue);
  }
  &.ns-backBtnBgBlueDark {
    @include getIcon(backBtnBgBlueDark);
  }
  &.ns-playBtnCircle {
    @include getIcon(playBtnCircle);
  }
  &.ns-playBtnCircleGreen {
    @include getIcon(playBtnCircleGreen);
  }
  &.ns-pauseBtnCircle {
    @include getIcon(pauseBtnCircle);
  }
  &.ns-pauseBtnCircleGreen {
    @include getIcon(pauseBtnCircleGreen);
  }
  &.ns-nextWhite {
    @include getIcon(nextWhite);
  }
  &.ns-prevWhite {
    @include getIcon(prevWhite);
  }
  &.ns-audioWhite {
    @include getIcon(audioWhite);
  }
  &.ns-distributed {
    @include getIcon(distributed);
  }
  &.ns-produziert {
    @include getIcon(produziert);
  }
  &.ns-settingsChecked {
    @include getIcon(settingsChecked);
  }
  &.ns-profiles_partly_created {
    @include getIcon(profiles_partly_created);
  }
  &.ns-warte_auf_freigabe {
    @include getIcon(warte_auf_freigabe);
  }
  &.ns-outdated {
    @include getIcon(outdated);
  }
  &.ns-telegramPurple {
    @include getIcon(telegramPurple);
  }
  &.ns-newFile {
    @include getIcon(newFile);
  }
  &.ns-contact {
    @include getIcon(contact);
  }
  &.ns-blueEar {
    @include getIcon(blueEar);
  }
  &.ns-fehlerhaft {
    @include getIcon(fehlerhaft);
  }
  &.ns-gespeichert {
    @include getIcon(gespeichert);
  }
  &.ns-in_production {
    @include getIcon(in_production);
  }
  &.ns-keptback {
    @include getIcon(keptback);
  }

  &.ns-trafficOren {
    @include getIcon(trafficOren);
  }

  &.ns-trafficYellow {
    @include getIcon(trafficYellow);
  }

  &.ns-trafficGreen {
    @include getIcon(trafficGreen);
  }

  &.ns-rewind10Sec {
    @include getIcon(rewind10Sec);
  }

  &.ns-forward10Sec {
    @include getIcon(forward10Sec);
  }

  &.ns-playBgWhiteCircle {
    @include getIcon(playBgWhiteCircle);
  }

  &.ns-doubleSpeed {
    @include getIcon(doubleSpeed);
  }

  &.ns-tripleSpeed {
    @include getIcon(tripleSpeed);
  }

  &.ns-volumeBgWhite {
    @include getIcon(volumeBgWhite);
  }

  &.ns-closeGray {
    @include getIcon(closeGray);
  }

  &.ns-msWord {
    @include getIcon(msWord);
  }

  &.ns-earWithBgWhite {
    @include getIcon(earWithBgWhite);
  }

  &.ns-profiles_planned {
    @include getIcon(profiles_planned);
  }

  &.ns-soundBite {
    @include getIcon(soundBite);
  }

  &.ns-soundBitePause {
    @include getIcon(soundBitePause);
  }

  &.ns-newMeldung {
    @include getIcon(newMeldung);
  }

  &.ns-calendarBgBlue {
    @include getIcon(calendarBgBlue);
  }

  &.ns-playOrangeRec {
    @include getIcon(playOrangeRec);
  }

  &.ns-pauseOrangeRec {
    @include getIcon(pauseOrangeRec);
  }
  &.ns-downloadNews {
    @include getIcon(downloadNews);
  }

  &.ns-downloadClient {
    @include getIcon(downloadClient);
  }

  &.ns-playBgAI {
    @include getIcon(playBgAI);
  }
  &.ns-pauseBgAI {
    @include getIcon(pauseBgAI);
  }
  &.ns-micPurple {
    @include getIcon(micPurple);
  }
  &.ns-telegram {
    @include getIcon(telegram);
  }
  &.ns-editStories {
    @include getIcon(editStories);
  }
  &.ns-saveWhite {
    @include getIcon(saveWhite);
  }
  &.ns-thumbsDown {
    @include getIcon(thumbsDown);
  }
  &.ns-estelleKopie {
    @include getIcon(estelleKopie);
  }
  &.ns-released {
    @include getIcon(released);
  }
  &.ns-distr_wait_profiles {
    @include getIcon(distr_wait_profiles);
  }
  &.ns-logout {
    @include getIcon(logout);
  }
  &.ns-no_production {
    @include getIcon(no_production);
  }
  &.ns-user {
    @include getIcon(user);
  }
  &.ns-greenCheck {
    @include getIcon(greenCheck);
  }
  &.ns-swapTraffic {
    @include getIcon(swapTraffic);
  }
  &.ns-minimizeBtn {
    @include getIcon(minimizeBtn);
  }
  &.ns-blueTriangle {
    @include getIcon(blueTriangle);
  }
  &.ns-micRec {
    @include getIcon(micRec);
  }
  &.ns-stopRec {
    @include getIcon(stopRec);
  }
  &.ns-stopRec {
    @include getIcon(stopRec);
  }
  &.ns-playBorderBlue {
    @include getIcon(playBorderBlue);
  }
  &.ns-pauseBlue {
    @include getIcon(pauseBlue);
  }
  &.ns-playBlue {
    @include getIcon(playBlue);
  }
  &.ns-saveBlue {
    @include getIcon(saveBlue);
  }
  &.ns-recordAll {
    @include getIcon(recordAll);
  }
  &.ns-blueMic {
    @include getIcon(blueMic);
  }
  &.ns-production_paused {
    @include getIcon(production_paused);
  }
  &.ns-gmaps {
    @include getIcon(gmaps);
  }
  &.ns-lowSpeed {
    @include getIcon(lowSpeed);
  }
  &.ns-mediumSpeed {
    @include getIcon(mediumSpeed);
  }
  &.ns-highSpeed {
    @include getIcon(highSpeed);
  }
  &.ns-crossGray {
    @include getIcon(crossGray);
  }
  &.ns-union {
    @include getIcon(union);
  }
  &.ns-melon {
    @include getIcon(melon);
  }
  &.ns-assistant {
    @include getIcon(assistant);
  }
  &.ns-headset {
    @include getIcon(headset);
  }
  &.ns-copyArrow {
    @include getIcon(copyArrow);
  }
  &.ns-copyTo {
    @include getIcon(copyTo);
  }
  &.ns-trafficGray {
    @include getIcon(trafficGray);
  }
  &.ns-trafficWhite {
    @include getIcon(trafficWhite);
  }
  &.ns-greenPlus {
    @include getIcon(greenPlus);
  }
  &.ns-greenPlusRounded {
    @include getIcon(greenPlusRounded);
  }
  &.ns-fileSelected {
    @include getIcon(fileSelected);
  }
  &.ns-upload {
    @include getIcon(upload);
  }
  &.ns-arrowBackGreen {
    @include getIcon(arrowBackGreen);
  }
  &.ns-arrowBackGreenDark {
    @include getIcon(arrowBackGreenDark);
  }
  &.ns-chevronDown {
    @include getIcon(chevronDown);
  }
  &.ns-chevronUp {
    @include getIcon(chevronUp);
  }
  &.ns-chevronLeftGray {
    @include getIcon(chevronLeftGray);
  }
  &.ns-addUser {
    @include getIcon(addUser);
  }
  &.ns-addUserBg {
    @include getIcon(addUserBg);
  }
  &.ns-sun {
    @include getIcon(sun);
  }
  &.ns-audioInput {
    @include getIcon(audioInput);
  }
  &.ns-audioOutput {
    @include getIcon(audioOutput);
  }
  &.ns-editRole {
    @include getIcon(editRole);
  }
  &.ns-submitp {
    @include getIcon(submitp);
  }
  &.ns-redTrash {
    @include getIcon(redTrash);
  }
  &.ns-addStory {
    @include getIcon(addStory);
  }
  &.ns-whiteTrash {
    @include getIcon(whiteTrash);
  }
  &.ns-redTrashDigs {
    @include getIcon(redTrashDigs);
  }
  &.ns-voiceAI {
    @include getIcon(voiceAI);
  }
  &.ns-greenMale {
    @include getIcon(greenMale);
  }
  &.ns-greenFemale {
    @include getIcon(greenFemale);
  }
  &.ns-create {
    @include getIcon(create);
  }
  &.ns-threeDot {
    @include getIcon(threeDot);
  }
  &.ns-userRectangle {
    @include getIcon(userRectangle);
  }
  &.ns-adminRectangle {
    @include getIcon(adminRectangle);
  }
  &.ns-DERectangle {
    @include getIcon(DERectangle);
  }
  &.ns-ENRectangle {
    @include getIcon(ENRectangle);
  }
  &.ns-accessDenied {
    @include getIcon(accessDenied);
  }
  &.ns-hamburger {
    @include getIcon(hamburger);
  }
  &.ns-addAudio {
    @include getIcon(addAudio);
  }
  &.ns-tableFilter {
    @include getIcon(tableFilter);
  }
  &.ns-playRoundedGreen {
    @include getIcon(playRoundedGreen);
  }
  &.ns-pauseRoundedGreen {
    @include getIcon(pauseRoundedGreen);
  }
  &.ns-playRoundedDisabled {
    @include getIcon(playRoundedDisabled);
  }
  &.ns-whiteMic {
    @include getIcon(whiteMic);
  }
  &.ns-greenUser {
    @include getIcon(greenUser);
  }
  &.ns-pauseGreen {
    @include getIcon(pauseGreen);
  }
  &.ns-nextAudioGray {
    @include getIcon(nextAudioGray);
  }
  &.ns-prevAudioGray {
    @include getIcon(prevAudioGray);
  }
  &.ns-prevAudioGray {
    @include getIcon(prevAudioGray);
  }
  &.ns-broadcastGreen {
    @include getIcon(broadcastGreen);
  }
  &.ns-broadcastGray {
    @include getIcon(broadcastGray);
  }
  &.ns-roundClock {
    @include getIcon(roundClock);
  }
  &.ns-ASCGray {
    @include getIcon(ASCGray);
  }
  &.ns-DESCGray {
    @include getIcon(DESCGray);
  }
  &.ns-volumeDigas {
    @include getIcon(volumeDigas);
  }
  &.ns-imgPlanungGray {
    @include getIcon(imgPlanungGray);
  }
  &.ns-imgPlanungGreen {
    @include getIcon(imgPlanungGreen);
  }
  &.ns-noImgBing {
    @include getIcon(noImgBing);
  }
  &.ns-successDpa {
    @include getIcon(successDpa);
  }
  &.ns-errorDpa {
    @include getIcon(errorDpa);
  }
  &.ns-emptyDpa {
    @include getIcon(emptyDpa);
  }
  &.ns-playGreen {
    @include getIcon(playGreen);
  }
  &.ns-stopGreen {
    @include getIcon(stopGreen);
  }
  &.ns-stopWhite {
    @include getIcon(stopWhite);
  }
  &.ns-playWhite {
    @include getIcon(playWhite);
  }
  &.ns-downloadGreen {
    @include getIcon(downloadGreen);
  }
  &.ns-downloadWhite {
    @include getIcon(downloadWhite);
  }
  &.ns-clockGreen {
    @include getIcon(clockGreen);
  }

  &.ns-clockWhite {
    @include getIcon(clockWhite);
  }
  &.ns-calendarWhite {
    @include getIcon(calendarWhite);
  }
  &.ns-arrowLeft {
    @include getIcon(arrowLeft);
  }
  &.ns-arrowLeftEnabled {
    @include getIcon(arrowLeftEnabled);
  }
  &.ns-arrowRight {
    @include getIcon(arrowRight);
  }
  &.ns-arrowRightEnabled {
    @include getIcon(arrowRightEnabled);
  }
  &.ns-burger {
    @include getIcon(burger);
  }
  &.ns-calendarGreen {
    @include getIcon(calendarGreen);
  }
  &.ns-logoutAs {
    @include getIcon(logoutAs);
  }
  &.ns-zeroResults {
    @include getIcon(zeroResults);
  }
  &.ns-copyRectangle {
    @include getIcon(copyRectangle);
  }
  &.ns-copyRectangleW {
    @include getIcon(copyRectangleW);
  }
  &.ns-mapBlack {
    @include getIcon(mapBlack);
  }
  &.ns-playGray {
    @include getIcon(playGray);
  }
  &.ns-downloadGray {
    @include getIcon(downloadGray);
  }
  &.ns-apple {
    @include getIcon(apple);
  }
  &.ns-windows {
    @include getIcon(windows);
  }
  &.ns-linux {
    @include getIcon(linux);
  }
  &.ns-sortTable {
    @include getIcon(sortTable);
  }
  &.ns-loginCon {
    @include getIcon(loginCon);
  }
  &.ns-office365 {
    @include getIcon(office365);
  }
}
