.login_container {
  font-family: Poppins;

  .left {
    background: linear-gradient(
        180deg,
        rgba(27, 177, 125, 0.81) 0.06%,
        rgba(40, 56, 144, 0.81) 158.35%
      ),
      url("../assets/bg-1-min.png");
    background-size: cover;
    background-position: center;

    .title {
      color: var(--white-100, #fff);
      text-align: center;
      font-family: "Forgotten Futurist";
      font-size: 56.622px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    .right_container {
      // height: 44vh;
      width: 60%;

      img.as_logo_login {
        width: 240px;
        height: auto;
        object-fit: cover;
        margin-bottom: 16px;
      }
    }
    .hello {
      color: #333;
      // font-family: Poppins;
      font-size: 31.844px;
      font-weight: 700;
    }

    .wb {
      color: #333;
      font-family: Poppins;
      font-size: 22.046px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .login_btn {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      color: #fff;
      border-radius: 30px;
      background: linear-gradient(180deg, #1bb17d 0.06%, #283890 158.35%);

      &.microsoft {
        background: none;
        border: 1px solid #d0d0d0;
        color: #706d6e;
      }
    }

    .microsoft_text {
      color: #000;
      font-family: "DM Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0 12px;
      margin-bottom: 12px;
    }
  }

  p.error-login {
    color: #e23342;
  }
}
