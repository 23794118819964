.basic {
    height: 80px;
    background: white;
    border: 2px solid transparent;
    border-radius: 4px;

    border-bottom: 2px solid #D8D8D8;

    .new_blue {
        background-color: #00ADEF;
        color: white;
        padding: 2px 4px;
        right: 4px;
        top: 4px;
        border-radius: 4px;
        font-weight: bold;

    }


    
    &.blue-bor {
        border-radius: 4px;
        border: 2px solid #00ADEF;
        
    }
    // margin-bottom: 18px;
    .upper {
        height: 40px;
        margin-bottom: 4px;
        .player_container {
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            border-radius: 2px;
            background: #FFF;
            box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.27);
            margin: auto 0;
            margin-right: 10px;
        }

        .title {
            color:  #333;
            font-family: "DM Sans";
            font-size: 14px;
            font-weight: 600;
            // text-transform: capitalize;
        }
    }

    .lower {
        border-radius: 6px;
        background:  #F5F5F5;
        padding: 4px 6px;

        .duration_as {
            border-radius: 5px;
            background: #E1E1E1;
            padding: 0 2px;
            margin-right: 8px;

            color: #6A6A6A;
            font-family: Lato;
            font-size: 14px;
            font-weight: 700;
        }

        .time {
            color:  #6A6A6A;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 114.286% */
        }
    }

}

.basic_img {
    width: 100%;
    height: 144px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
    padding: 10px;
    margin-bottom: 6px;
    border: 2px solid transparent;

    .new_blue {
        background-color: #00ADEF;
        color: white;
        padding: 2px 4px;
        right: 4px;
        top: 4px;
        border-radius: 4px;
        font-weight: bold;

    }


    
    &.blue-bor {
        // border-radius: 4px;
        border: 2px solid #00ADEF;
        
    }


    .left {
        margin-right: 10px;
        .title {
            color: #333;
            font-family: "DM Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .sub_title {
            color:  #333;
            font-family: "DM Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            letter-spacing: 0.25px;
        }

        .info {
            background: #f5f5f5;
            padding: 1px 9px;
            border-radius: 6px;

            .duration_as {
                background-color: #e1e1e1;
                border-radius: 6px;
                color: #6A6A6A;
                font-family: Lato;
                font-size: 14px;
                font-weight: 700;
                padding: 0px 3px;
            }

            .time {
                color:  #6A6A6A;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px; /* 114.286% */
            }
        }
    }

    .right {
        .img_container {
            height: 100%;
            border-radius: 6px;
            overflow: hidden;

            img {
                height: 124px;
                width: 100%;
                object-fit: cover;

                &.no_image {
                    object-fit: contain; 
                    background: #f5f5f5;
                }
            }
        }
    }
}