.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent; /* White with 80% opacity */
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  &.black {
    background: #272723;
  }

  &.top {
    // Your specific styles for .modal-content.right here
    top: 40%;
    left: auto;
    transform: translate(0, -50%);
    // bottom: 0;
  }

  &.mob {
    width: 95%;
    max-height: 60vh;
    // overflow-y: auto;
  }
}


.modal-header {
  p {
      font-size: 20px;
      color: #515456;

      &.estimation {
        color: #fff;
      }
  }
}

.modal_ai {
  width: 468px;
  height: 445px;
}