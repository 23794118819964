.carousel {
  width: 100%;
  // overflow: hidden;
  position: relative;
  margin-bottom: 28px;

  .new {
    background: #e6323d;
    color: #fff;
    text-transform: uppercase;
    padding: 2px 6px;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 6px;
  }
}

.carousel__container {
  display: flex;
  transition: transform 0.5s ease; /* Smooth scrolling effect */
  will-change: transform; /* Optimize for performance */
}

.carousel__slide {
  flex: 0 0 25%; /* Each slide takes up 25% of the carousel width */
  box-sizing: border-box;
  padding: 4px; /* Default padding around each item */

  &.top_img {
    flex: 0 0 20%; /* Each slide takes up 25% of the carousel width */
  } 
  &.featured {
    flex: 0 0 20%;
    padding: 4px 0;
  } 
}

@media (max-width: 1600px) { /* Adjust the breakpoint as needed */
  .carousel__slide {
    flex: 0 0 25%; /* Each slide takes up 25% of the carousel width on smaller screens */
  }

  .carousel__slide.top_img,
  .carousel__slide.featured {
    flex: 0 0 25%; /* Consistent for specific classes */
  }
}

@media (max-width: 1260px) { /* For even smaller screens */
  .carousel__slide {
    flex: 0 0 50%; /* Each slide takes up 50% of the carousel width */
  }

  .carousel__slide.top_img,
  .carousel__slide.featured {
    flex: 0 0 50%;
  }
}

.carousel__slide:nth-child(4n + 1),
.carousel__slide:nth-child(5n + 1){
  padding-left: 0; /* Remove padding on the left side for the first item in each row of four */
}

.carousel__slide:nth-child(4n + 4),
.carousel__slide:nth-child(5n + 4)

{
  padding-right: 0; /* Remove padding on the right side for the fourth item in each row of four */
}

.arrow_container {
  cursor: pointer;
}


.carousel__container {
  position: relative;
}

.carousel-controls {
  position: absolute;
  top: 0px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 10; /* Ensure this is higher than Swiper's z-index */
}

.swiper-button-next,
.swiper-button-prev {
  background: transparent;
  border: none;
  cursor: pointer;
}

.swiper-button-next i,
.swiper-button-prev i {
  font-size: 1.5rem;
}

