.custom-input-container {
  position: relative;

}

.close_input{
  transform: translateY(-50%);
  top: 50%;
  right: 12px;

  &.mob {
    top: 43%;
  }
}
.svg-icon {
  position: absolute;
  left: 12px;
  top: 57%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;

  &.mob {
    top: 62%;
  }
}

.custom-input {
  // padding: 8px;
  padding-left: 40px; /* Adjust this value based on the icon width */
  border: 1px solid #ccc;
  border-radius: 16px;
  font-size: 16px;
  outline: none;
  border: none;
  width: 100%;
  font-weight: normal;
  height: 100%;
  box-sizing: border-box; 

  &.mob {
    height: 24px;
  }
}

// .custom-input::placeholder {
//   font-size: 12px;
// }

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  // background-color: $light-blue-1;
  background: var(--Gradient-Primary-GRADIENT, linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%));
  border-radius: 20px;
  border: transparent;
}

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 6px;
  background: #ccc;
  border-radius: 6px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 6px;
  background: #ccc;
  border-radius: 6px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #fff;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #fff;
}

// toggle

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
  
  &.autoplay {
    background: #ccc;
  }

  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.black:before {
  background-color: #1f1f1c;
}

input:checked + .slider {
  // background-color: #2196F3;
  background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
}

input:focus + .slider {
  box-shadow: 0 0 1px transparent;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.language-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

textarea:focus {
  outline: none;
}

textarea {
  resize: none;
}

.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  width: 100%; // Adjust the width as needed

  .search-input {
    flex: 1;
    padding: 10px 15px;
    border: none;
    outline: none;
    font-size: 14px;
    color: #555; // Adjust the text color as needed

    &::placeholder {
      color: #aaa; // Placeholder text color
    }
  }

  .search-button {
    background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%); // Updated gradient background
    border: none;
    padding: 10px 20px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0; // Rounded corners on the right side

    .search-icon {
      margin-right: 5px; // Space between icon and text
    }

    &:hover {
      background: linear-gradient(180deg, #1bb17d 0.06%, #1f3270 158.35%); // Darker gradient on hover
    }
  }
}

.custom-input {
  font-family: Poppins;

  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 10px;
  width: 100%;
  height: 50px;
  margin-bottom: 12px;

  &.error {
    border: 1px solid #e23342;
  }

  .icon-wrapper {
    margin-right: 10px;
  }

  input {
    border: none;
    outline: none;
    flex: 1;
    color: #333;
  }
}

.search-input-w {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  border: 0.643px solid #EBEEF2;

  transition: width 0.3s ease-in, padding 0.3s ease;


  padding: 10px 15px;
  width: 100%;
  width: 400px;

  &.hide {
    width: 0px;
    padding: 0;

  }

  .search-icon {
    // margin-right: 10px;
    img {
      width: 1.5rem; // Adjust this as per your needs
      height: 1.5rem;
    }
  }

  .input-field {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    font-size: 16px;
    color: #333;

    &::placeholder {
      color: #aaa;
    }
  }
}

.switch-container {
  display: flex;
  justify-content: center;
  // margin-top: 10px;
  margin-left: 8px;

  .switch-button {
    display: flex;
    background-color: #E1E1E1;
    border-radius: 20px;
    // padding: 5px;
    width: fit-content;

    font-family: "Forgotten Futurist";

    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    .switch-option {
      padding: 0px 6px;
      border-radius: 12px;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;

      color: #898989;

      &.active {
        background-color: white;
        color: #00B17E;
        font-weight: bold;

        box-shadow: 0px 0px 3.273px 0px rgba(0, 0, 0, 0.23);
      }
    }
  }
}



