.welcome {
  position: relative;
  height: 220px;
  margin-bottom: 28px;
  border-radius: 8px;
  overflow: hidden; // Ensures the rounded corners are applied to the gradient overlay as well

  // Background wrapper for images and gradient overlay
  .background-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;

    // Gradient overlay
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(44, 44, 44, 0.49) 32.21%,
        #1bb17d 114.09%
      );
      z-index: 1;
      pointer-events: none; // Allows clicks through the gradient overlay
    }
  }

  // Main content positioned above the background and gradient
  .content {
    position: relative;
    z-index: 1; // Ensures content is above the gradient overlay
    height: 100%;

    p.as_log {
      color: #fff;
      font-family: "Forgotten Futurist";
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .title {
      color: #fff;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "DM Sans";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 47px; /* 130.556% */
      // text-transform: capitalize;
    }

    .jigo_container {
      background: #fff;
      border-radius: 4px;
      // width: 300px;
      width: 348px;

      .text {
        margin: 0 6px;
        color: #79829f;
        font-family: "DM Sans";
        font-size: 14px;
        font-weight: 700;
        user-select: none;
      }
    }

    .announcement {
      width: 90%;
      // height: 80px;
      margin-bottom: 16px;
      border-radius: 4px;
      text-align: center;
      padding: 4px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 668px;
      margin-top: 10px;

      border-radius: 16px;
      // background: linear-gradient(
      //     202deg,
      //     rgba(0, 177, 126, 0) 30.54%,
      //     rgba(0, 177, 126, 0.2) 84.15%
      //   ),
      //   linear-gradient(
      //     181deg,
      //     rgba(255, 255, 255, 0.5) 1.15%,
      //     rgba(255, 255, 255, 0.15) 61.27%,
      //     rgba(255, 255, 255, 0.1) 77.89%,
      //     rgba(255, 255, 255, 0.05) 90.6%,
      //     rgba(255, 255, 255, 0.03) 96.95%,
      //     rgba(255, 255, 255, 0) 96.96%
      //   );
      // backdrop-filter: blur(35px);

      .header {
        color: #fff;
        font-family: "DM Sans";
        font-size: 12px;
        font-weight: 600;
        // user-select: none;
        font-style: italic;

        span.link {
          // color: #007bff;
          text-decoration: underline;
          font-weight: 700;

          &.normal {
            font-style: normal;
            color: white;
          }
        }
      }
    }
  }
}

.search-audioshop {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 30px;
  padding: 4px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // max-width: 600px;
  margin: 0 auto;
  width: 100%;

  input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 5px;
    color: #333;
    border-radius: 30px;
    background: transparent;

    &::placeholder {
      color: #a1a1a1;
    }
  }

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #4caf50; // Green color for the icon
      width: 24px;
      height: 24px;
    }

    &:hover svg {
      fill: #388e3c; // Darker green on hover
    }
  }
}

.dpa_audio {
  margin-bottom: 28px;

  .dpa_container {
    row-gap: 8px;
    column-gap: 12px;

    .container_multi_img {
      background: #f1f1f1;
      padding: 10px;
      border-radius: 12px;
    }
  }

  .new {
    background: #e6323d;
    color: #fff;
    text-transform: uppercase;
    padding: 2px 6px;
    font-weight: bold;
    border-radius: 6px;
    margin-left: 6px;
  }
}

.green_image {
  width: 100%;
  // width: 300px;
  height: 218px;
  // background: linear-gradient(180deg, rgba(44, 44, 44, 0.10) 33%, #11B37B 100%),
  // url('../assets/test.svg') lightgray 50% / cover no-repeat;
  border-radius: 8px;
  padding: 11px;

  .new_blue {
    background-color: #00adef;
    color: white;
    padding: 2px 4px;
    right: 4px;
    top: 4px;
    border-radius: 4px;
    font-weight: bold;
  }

  &.blue-bor {
    // border-radius: 4px;
    border: 2px solid #00adef;
  }

  .title {
    color: #fff;
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px; /* 125% */
    // margin-top: 14px;
  }

  .duration_as {
    color: #6a6a6a;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    // text-transform: capitalize;
    background: #fff;
    border-radius: 5px;
    width: 50px;
    text-align: center;
    margin: 0 12px;
  }

  .time {
    color: #fff;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
  }
}

.header {
  color: #000;
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: 600;
  // border-bottom: 1px solid #eee;
  // padding-bottom: 4px;

  &.no_border {
    border: none;
    padding: 0;
  }

  &.multi_img {
    border: none;
    padding: 0;
    color: #000;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 300;
    text-transform: lowercase;
    // margin-bottom: 10px;
  }

  &:focus {
    border-radius: 4px;
    outline: 2px solid #007bff; /* Blue outline */
    outline-offset: 2px; /* Space between outline and text */
  }
}

.bigger {
  margin-bottom: 18px;
}

.floating_player {
  height: 0;
  background: #f5f5f5;
  position: sticky;
  top: 0px;
  margin-bottom: 16px;
  z-index: 2;
  overflow: hidden; /* Prevents content from spilling out during transition */
  transition: height 0.3s ease; /* Adjust duration and timing function as desired */
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;

  .current_title {
    color: #5f5f5f;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 4px;
  }

  &.show {
    height: 92px;
  }

  #waveContainer {
    background: #f5f5f5;
    position: relative;

    &.gray_bg {
      background: url("../assets/ws_bg.svg");
      // background: red;
      background-size: contain;
      background-repeat: repeat;
    }

    div.loadingWs {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  p.duration_gl {
    color: #0db07d;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.128px; /* 88.3% */

    &.disabled {
      color: #aeaeae;
    }
  }
  .wave_white {
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.09) inset;

    &.is_playing {
      padding-bottom: 6px;
    }
  }

  img.blr_logo_player {
    height: auto;
    width: 140px;
    // object-fit: cover;
  }

  .username_container_float {
    width: 200px;

    &.min {
      width: 140px;
    }
    // margin-right: 2px;
    margin-left: 8px;
    p.username_floating {
      font-family: "DM Sans";
      font-size: 14px;
      font-weight: 600;
      margin-top: 4px;
    }
  }
}

.top_image {
  height: 100%;
  // width: 264px;
  // height: 430px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
  padding: 10px;
  // width: 244px;
  width: 100%;
  margin: 10px;
  margin-top: 0px;

  &.margin-sm {
    margin-right: 8px;
    margin-left: 0;

    &.mm {
      margin-left: 8px;
    }
  }

  .top_image_container {
    border-radius: 12px;
    overflow: hidden;
    display: inline-block;
    .img_top {
      height: 230px;
      width: 100%;
      object-fit: cover;

      &.no_image {
        object-fit: contain;
      }
    }

    .flag {
      padding: 4px;
      // margin-right: 4px;
      border-radius: 4px;
      background: #00adef;
      color: #fff;
      font-family: "DM Sans";
      font-size: 14px;
      font-weight: 600;

      display: flex;
      align-items: center;
      justify-content: center;

      &.tomoro {
        margin-top: 12px;
        right: 10px;
        width: 110px;
      }
      &.shortly {
        top: 46px;
        right: 10px;
        width: 110px;
        background: #f8794e;
      }

      &.cat {
        background: rgba(32, 32, 32, 0.66);
        margin: 0;
        bottom: 12px;
        left: 10px;
      }
    }
  }

  p.title {
    overflow: hidden;
    color: #2f2f2f;
    font-feature-settings: "liga" off, "clig" off;
    text-overflow: ellipsis;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    // text-transform: capitalize;
    height: 42px;
  }

  p.sub_title {
    // overflow: hidden;
    // color: var(--Black-75, rgba(62, 50, 50, 0.75));
    color: #333;
    font-family: "DM Sans";
    font-size: 12px;
    font-weight: 400;
    // height: 50px;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.25px;
    // text-transform: capitalize;
  }

  .info {
    background: #f5f5f5;
    padding: 1px 9px;
    border-radius: 6px;

    .duration_as {
      background-color: #e1e1e1;
      border-radius: 6px;
      color: #6a6a6a;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      padding: 0px 3px;
    }

    .time {
      color: #6a6a6a;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
    }

    .vorr {
      color: #6a6a6a;
      font-family: "DM Sans";
      font-weight: 600;
    }
  }
}

.recent {
  width: 80vw;
  height: 80vh;
  padding: 18px;
  padding-top: 0;

  &.search {
    width: 100%;
    padding: 0;
    height: 100%;
  }

  &_player {
    height: 50px;
    display: grid;
    grid-template-columns: 96px 4fr 2fr 46px 176px;
    align-items: center;
    // padding: 10px 0;
    padding-left: 8px;

    &.four {
      grid-template-columns: 96px 4fr 46px 176px;
    }

    &.search {
      grid-template-columns: 1fr 4fr 2fr 1fr 2fr 2fr;
    }

    &.dark {
      background: #f7f7f7;
    }

    .player_container {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 2px;
      background: #fff;
      box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.27);
      margin: auto 0;
      margin-right: 10px;
    }

    .title_r {
      color: #333;
      font-family: "DM Sans";
      font-size: 14px;
      font-weight: 500;
      // text-transform: capitalize;
      word-break: break-all;
      margin-right: 14px;
      text-align: start;
    }

    .sub_title {
      color: #333;
      text-overflow: ellipsis;
      // white-space: nowrap;
      word-break: break-all;
      font-family: "DM Sans";
      font-size: 12px;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.25px;
      margin-right: 4px;
      cursor: default;
    }

    .date,
    .duration_as {
      color: #6a6a6a;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      // text-transform: capitalize;
    }

    .img_recent {
      height: 32px;
      width: 32px;
      object-fit: cover;
      border-radius: 2px;

      &.jumbo {
        width: 100%;
        height: 150px;
      }
    }
  }

  .header_table {
    display: grid;
    grid-template-columns: 96px 4fr 2fr 46px 176px;

    padding: 10px 0;
    padding-left: 8px;
    padding-right: 12px;
    padding-top: 0;
    font-weight: bold;
    padding-bottom: 8px;
    // background-color: #f1f1f1;

    &.search {
      padding-right: 6px;
      grid-template-columns: 1fr 4fr 2fr 1fr 2fr 2fr;
    }

    &.four {
      grid-template-columns: 96px 4fr 46px 176px;
    }

    p {
      margin: 0;
      color: #7f7f7f;
      font-family: "DM Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.5px;
    }
  }

  .list {
    height: calc(80vh - 50px);
    overflow: auto;
    padding-right: 6px;

    &.search {
      height: calc(100vh - 460px);
      // height: 400px;
      // height: 100%;
      overflow: auto;
      padding-right: 0px;

      &.audio {
        height: calc(100vh - 466px);
      }
      &.sf {
        height: calc(100vh - 224px);
      }
    }

    .group-text {
      position: relative;

      height: 16px;
      // background: #00adef;
      color: #3486d6;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;

      .blue-line {
        height: 1px;
        background: #3486d6;

        &.begin {
          width: 94px;
          margin-right: 6px;
        }

        &.end {
          margin-left: 6px;
        }
      }
    }
  }
}

.tooltip_remark {
  padding: 10px;
  width: 50vw;
  border: 2px solid #00b17e;

  .title_toop {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .text {
    max-height: 60vh;
    overflow: auto;

    &.green {
      max-height: 130px;
    }
  }
}

.featured_container {
  .featured {
    // border-radius: 12px;
    padding-top: 10px;

    &.aktuell {
      background: rgba(20, 123, 223, 0.25);
    }

    &.sport {
      background: rgba(214, 114, 51, 0.25);
    }

    &.bunt {
      background: rgba(144, 38, 231, 0.25);
    }
    &.wirtschaft {
      background: rgba(150, 194, 19, 0.25);
    }
    &.service {
      background: rgba(0, 177, 126, 0.29);
    }
    &.musik {
      background: rgba(223, 20, 220, 0.25);
    }

    &.kirche {
      background: rgba(169, 169, 169, 0.25);
    }

    &.first {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    &.last {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      // margin-right: 4px;
    }
  }

  .horizontal-text {
    writing-mode: vertical-rl; /* Set text to vertical */
    // transform: rotate(180deg); /* Rotate to display horizontally */
    text-orientation: upright; /* Orient  upright */
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    font-family: "DM Sans";
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;

    &.sport {
      color: #ff873b;
    }

    &.aktuell {
      color: #3386d6;
    }

    &.bunt {
      color: #9026e7;
    }
    &.wirtschaft {
      color: #3d9d0a;
    }
    &.service {
      color: #00b17e;
    }

    &.musik {
      color: #d63395;
    }
  }
}

.border-bttm-eee {
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.no_found {
  height: calc(100vh - 366px);
  color: #000;
  font-family: "DM Sans";

  .big {
    font-size: 28px;
    font-weight: 700;
  }

  .small {
    font-size: 20px;
    font-weight: 400;
  }
}

.jigo {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: linear-gradient(180deg, #1bb17d 0.06%, #283890 158.35%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.38) inset,
    0px 1px 7px 1px rgba(0, 0, 0, 0.26);

  color: #fff;
  font-family: "Forgotten Futurist";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 126%; /* 38.811px */
  user-select: none;
}

.search_res {
  color: #6a6a6a;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;

  .result {
    // padding: 0 8px;
    margin-bottom: 2px;

    .keyword {
      color: #000;
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.footer {
  min-height: 121px;
  border-radius: 24px 24px 0px 0px;
  background: #f8f8f8;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);
  padding-top: 20px;

  color: #252525;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;

  // padding: 0 40px;

  .parking {
    font-weight: 400;
    font-size: 14px;
  }

  .right {
    width: 263px;

    .kontak {
      color: #000;
      margin-bottom: 16px;
    }

    .info_header {
      color: rgba(4, 5, 5, 0.8);
    }

    .info_info {
      font-weight: 400;
    }
  }

  .black_line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.32);
    width: 100%;
    height: 2px;
    margin: 20px 0;
  }

  .power {
    color: #777;
  }

  .hor_line {
    border-right: 1px solid #ccc;
    height: 16px;
    margin: 0 8px;

    &.max {
      height: 40px;
      margin: 0 16px;
    }
  }

  .footer_img {
    object-fit: cover;

    &.rc_img {
      width: 32px;
    }
    &.blr_logo {
      width: 100px;
    }
    &.as_logo {
      width: 45px;
    }
  }

  .bottom_grad {
    background: linear-gradient(90deg, #1bb17d 0.01%, #283890 100.01%);
    height: 4px;
    margin-top: 20px;
  }
}

.as_text {
  background: linear-gradient(107deg, #1bb17d 25.2%, #283890 74.4%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-align: center;
  font-family: "Forgotten Futurist";
  font-size: 15.778px;
  font-weight: 700;
}

.admin-container {
  width: 90vw;
  // height: calc(80vh - 40px);
  height: 96vh;
  overflow: auto;
}

.table-container-admin {
  display: flex;
  flex-direction: column;
  width: 100%;
  // border: 1px solid #ccc;

  font-family: "DM Sans";

  .table-row {
    display: grid;
    grid-template-columns: repeat(5, minmax(50px, 1fr)) 160px 40px 40px 160px repeat(
        1,
        minmax(400px, 1fr)
      );
    font-size: 12px;

    // border-bottom: 1px solid #ddd;
    align-items: center;
    min-height: 20px;

    &.data.black {
      background: #f7f7f7;
    }

    &:last-child {
      border-bottom: none;
    }

    &.logout {
      opacity: 0.5;
      background: #d3d3d3;
    }

    .table-cell-custom {
      padding-left: 8px;

      margin-right: 6px;

      word-break: break-all;

      color: #333;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      // margin-right: 4px;
      // cursor: default;

      &:last-child {
        border-right: none;
      }
    }
  }

  .table-data {
    height: calc(96vh - 40px);
    overflow: auto;
  }

  .header {
    &.m_30 {
      margin-right: 6px;
    }
    // margin-left: 10px;

    .header-cell {
      margin: 0;
      color: #7f7f7f;
      font-family: "DM Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      text-transform: capitalize;
      padding-left: 8px;
    }
  }
}

.text_only {
  border-radius: 4px;
  background: var(--Neutral-200, #d2d4da);
  color: #6a6a6a;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.16px;
  padding: 2px 6px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.active {
    background: var(
      --LandingPage-Gradient,
      linear-gradient(91deg, #1bb17d 31.32%, #283890 105.5%)
    );
    color: #fff;
  }
}

.desc_hidden {
  padding: 0 8px;
  padding-bottom: 6px;
  &.dark {
    background: #f7f7f7;
  }

  p.title_desc {
    color: #000;
    font-family: "DM Sans";
    font-size: 12px;
    font-weight: 400;
  }
}

.subheader {
  color: #6a6a6a;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
}

.date_aktelle {
  color: #6a6a6a;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
}
