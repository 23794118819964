.signin-box {
  margin-top: 50px;
  text-align: center;
  font-size: 80%;
}
.signin-btn {
    background: #3498db;
    background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
    background-image: -moz-linear-gradient(top, #3498db, #2980b9);
    background-image: -ms-linear-gradient(top, #3498db, #2980b9);
    background-image: -o-linear-gradient(top, #3498db, #2980b9);
    background-image: linear-gradient(to bottom, #3498db, #2980b9);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    padding: 20px 30px 20px 30px;
    margin: 20px 30px 20px 30px;
    text-decoration: none;
  }
  
  .signin-btn:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    text-decoration: none;
  }

  .signout-btn {
    background: #e6a6a6;
    background-image: -webkit-linear-gradient(top, #e6a6a6, #ff5c5c);
    background-image: -moz-linear-gradient(top, #e6a6a6, #ff5c5c);
    background-image: -ms-linear-gradient(top, #e6a6a6, #ff5c5c);
    background-image: -o-linear-gradient(top, #e6a6a6, #ff5c5c);
    background-image: linear-gradient(to bottom, #e6a6a6, #ff5c5c);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    font-family: Arial;
    color: #ffffff;
    font-size: 14px;
    padding: 15px 20px 15px 20px;
    margin: 20px 30px 20px 30px;
    text-decoration: none;
  }
  
  .signout-btn:hover {
    background: #ad0c24;
    background-image: -webkit-linear-gradient(top, #ad0c24, #91091d);
    background-image: -moz-linear-gradient(top, #ad0c24, #91091d);
    background-image: -ms-linear-gradient(top, #ad0c24, #91091d);
    background-image: -o-linear-gradient(top, #ad0c24, #91091d);
    background-image: linear-gradient(to bottom, #ad0c24, #91091d);
    text-decoration: none;
  }