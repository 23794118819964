.ms-word {
    // height: 90vh;
    border-radius: 12px;
    padding: 20px;
    width: 80vw;

    background:white;

    &.black {
        background: #1f1f1c;
    }


    &_dates {
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        height: 40px;
        margin-left: 6px;
        padding: 10px;
        color: #000;

        font-size: 20px;
        font-weight: 500;
        line-height: 18.405px; 

        p.date {
            color: #000;
            &.black {
                color: #f3f4f8;
            }
        }

        .clock {
            border-radius: 4px;
            border: 1px solid #0469AE;
            padding: 3px 9px;
            color: #000;

            &.black {
                color: #f3f4f8;
            }

            &.active {
                background: linear-gradient(91deg, #1BB17D 31.32%, #283890 105.5%);
                color: #fff;
            }
            &.disabled {
                background-color: #EBEBE4;
                color: #C6C6C6;
                border: 1px solid #C6C6C6;
            }
        }
    }

    &_text {
        .markedCol {
            padding-top: 1px;
            height: calc(64vh);
            transition: width 0.3s ease-in-out;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .textArea {
            height: calc(64vh);
            border: 1px solid #D0D5DD;
            border-radius: 8px;
            padding: 12px;

            &.black {
                color: #f3f4f8;
                background: #272723;
            }
        }

        p.new_text {
            color: #000;

            &.black {
                color: #f3f4f8;
            }
        }
    }

    &_title_blank {
        // width: 1100px;
        max-width: 1200px;
        min-width: 500px;
    }
}