.homepage_container {
    background: #fff; 

    &.black {
        background: #272723;
    }
    .footer {
            color: $gray-1;
        }

    &_content {
        background: #fff;
        &.black {
            background: #272723;
        }
        .time_container {
            font-size: 14px;
            font-weight: 400;
            color: #484848;
            width: 200px;

            &.black {
                color: #f3f4f8;
            }

            .created {
                color: $light-blue-1;
                font-size: 12px;

                &.black {
                    color: #1BB17D;
                }
            }

            .word {
                width:126px;
            }

            .time {
                width: 60px;
                text-align: end;
            
            }

            .profiles {
                width:70px;
            }

            .clients {
                width: 70px;
                text-align: end;
            
            }

            &.mob {
                width: 100%;
                background: #f3f3f3;
            }
        }
        .card_container {
            min-height: 80vh;
        }

        .title {
            &.black {
                color: #f3f4f8;
            }
        }

        .tray {
            background: #f6f6f6;

            &.black {
                background: #1f1f1c;
            }
        }
    }
}