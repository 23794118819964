.user_mana {
    background: #f6f6f6;

    &.black {
        background: #272723;
    }

    .digas_nav {
        background: white;
        height: 60px;
        padding: 30px;

        .logo_digs {
            margin-right: 16px;
        }

        .title {
            color: #0DB07D;
            // font-family: "DM Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            &.username {
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

    &_container {
        padding: 30px;

        &.digs {
            padding: 0 14px;
        }

        .title {
            color: #0DB07D;
            font-family: "DM Sans";
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .table {
            background: #fff;
            height: calc(100vh - 120px);
            margin-top: 14px;
            border-radius: 30px;
            padding: 20px 22px;
            width: 100%;

            &.digs {
                height: calc(100vh - 100px);
                padding-bottom: 6px;

                .week {
                    height: calc(100vh - 180px);
                    margin-top: 12px;

                    &_item {
                        border-radius: 16px;
                        background: #F5F6F7;
                        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
                        padding: 10px 7px;
                        

                        .day {
                            color: #00B17E;
                            font-family: "DM Sans";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20px; /* 142.857% */
                        }

                        .date {
                            color: #6A6A6A;
                            font-family: Lato;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.12px;
                        }

                        .audio_list {
                            overflow-y: auto;
                            overflow-x: hidden;
                        }

                        .badge_digs {
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;

                            .badge {
                                height: 14px;
                                width: 14px;
                                border-radius: 50%;
                                color: white;
                                font-size: 8px;
                                right: -6px;
                                top: -6px;

                                &.success {
                                    background: #00B17E;
                                }
                                &.error {
                                    background: #D73133;
                                }
                                &.empty {
                                    background: #737373;
                                }
                            }
                        }
                    }
                    
                }
            }

            &_title {
                color: #494949;

                &.black {
                    color: #f3f4f8;
                }
            }

            &.black {
                background: #1f1f1c;
            }

            // padding-left: 44px;
            // padding-right: 44px;

            .header {
                color: #000;
                font-family: "DM Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                // margin-bottom: 8px;
            }

            .user-list {
                width: 100%;
                overflow-x: auto;

                p.entries {
                    color: #B5B7C0;
                    font-family: "DM Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.14px;
                }
            }
            
            .user-list-header, .user-list-item {
                display: flex;
                justify-content: flex-start; /* Align items to the start */
                align-items: center;
                padding: 6px 0;

                &.digs {
                    padding: 1px 0;
                    color:  #6A6A6A;
                    font-family: "DM Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.14px;

                    &.weekly {
                        min-width: 0;
                        background: #F5F6F7;
                        padding: 0;
                        overflow-x: hidden;
                    }
                }
                border-bottom: 1px solid #ddd;
                &.black {
                    border-bottom: 1px solid #404040;
                }
                min-width: 800px;

                
            }

            .parent_digs {
                .tooltip-metadata {
                    // height: 240px;
                    padding: 10px;

                    .meta_title {
                        color: #00B17E;
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;

                        .gray {
                            color: #6a6a6a;
                        }
                    }

                    #meter {
                        width: 100%;
                        height: 6px;
                        background-color: transparent;
                        border: 1px solid #fff;
                        position: relative;
                        margin-top: 4px;
                        overflow: hidden;
                        border-radius: 0;
            
                        border-radius: 6px;
            
                        &.mob {
                            height: 8px;
                        }
            
                        
                        
                    }
            
                    #mask {
                        background: linear-gradient(90deg, 
                            #007F00 0%,               /* 25% Lighter Dark Green */
                            #00AA00 25%,              /* 25% Lighter Medium Green */
                            #00CC00 50%,              /* 25% Lighter Light Green */
                            #CCFF00 75%,              /* 20% Yellow */
                            #FF0000 100%              /* 5% Red */
                        );
                        height: 100%;
                        width: calc(100% - 2px);
                        border-radius: 6px;
                    }
            
                    
                    #meter #bar {
                        width: 100%;
                        height: 100%;
            
                        border-bottom-right-radius: 6px;
                        border-top-right-radius: 6px;
                        -webkit-transition: all .1s ease-in-out;
                        -moz-transition: all .1s ease-in-out;
                        -ms-transition: all .1s ease-in-out;
                        -o-transition: all .1s ease-in-out;
                        transition: all .1s ease-in-out;
                        position: absolute;
                        top: 0;
                        right: 1px;
                    }
            
                    .wave-container {
                        max-width: 100%;
                        position: relative;
                        border-radius: 4px;
                        height: 50px;
            
                        &.black {
                            background-color: #2e2e2e;
                        }
                        &.mob {
                            height: 25px;
                        }
                        
                        
                        
                        // #waveform { width: 100%; }
            
                        // #waveform ::part(hover-label):before {
                        //     content: '';
                        // }
            
                        div.loadingWs {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 10;
                        
                        }
            
                        
                    }

                    .info_audio {
                        width: 130px;
                        margin-right: 8px;

                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        height: 60px;

                        .current_time {
                            color: #0DB07D;
                        }

                        .total_dur {
                            color: #AEAEAE;
                        }
                    }

                    .info_digs_popup {
                        color: #4F4F4F;
                        font-family: "DM Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.14px;
                    }
                }

                .slice {
                    background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
                }

                .textArea_digs {
                    border: 1px solid #0DB07D;
                    padding: 2px;
                    border-radius: 4px;
                    height: 90%;
                }

                .input_digs:focus {
                    outline: none;
                    border: 1px solid #0DB07D;
                    border-radius: 4px;
                    box-shadow: none; /* removes any box shadow */
                }

                .is_cvd {
                    color: #FFF;
                    font-family: "DM Sans";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -0.121px;
                    background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
                    padding: 1px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    user-select: none;

                    &.dpa {
                        background: #0c65e3;
                    }
                    &.gray {
                        background: #cecece;
                        color: #686767;
                    }
                }

                .react-date-picker__inputGroup {
                    min-width: 82px;
                    font-family:Lato,system-ui,sans-serif;

                }

                .react-datetime-picker__inputGroup {
                    width: 160px !important;
                    font-family:Lato,system-ui,sans-serif !important;


                    &__input {
                        font-family:Lato,system-ui,sans-serif !important;
                    }

                    // &__year {
                    //     width: 34px !important;
                    // }
                    // &__month {
                    //     width: 9px !important;
                    // }
                    // &__day {
                    //     width: 18px !important;
                    // }

                    // &__hour,&__minute,&__second {
                    //     width: 16px !important;
                    // }

                }
                
                // .react-calendar__tile--now {
                //     background: transparent !important;
                //     border: 1px solid #737373;
                //     color: white;
                //     opacity: 0.8;
                // }

                .react-calendar__tile--active {
                    background: #00B17E !important;
                    color: white;
                    opacity: 0.8;
                }
                
                
            }
            
            .user-list-header {
                background: #fff;
                color: #2C2C2C;
                font-size: 14px;
                font-style: normal;
                // font-weight: bold;

                &.black {
                    background: #1f1f1c;
                    color: #f3f4f8;
                }
            }

            .user-list-item-container {
                height: calc(100vh - 256px);
                // max-height: calc(100vh - 270px);
                overflow-y: auto;

                &.digs {
                    height: calc(100vh - 230px);
                }

                
            }

            .user-list-header div.email, .user-list-item div.email {
                min-width: 300px; /* Minimum width for the email column */
            }
            .user-list-header div.photo, .user-list-item div.photo {
                min-width: 100px; /* Minimum width for the email column */
                max-width: 100px;
            }
            .user-list-header div.action, .user-list-item div.action {
                min-width: 138px; /* Minimum width for the email column */
                max-width: 138px;

                &.dpa {
                    min-width: 170px; /* Minimum width for the email column */
                    max-width: 170px;

                    &.weekly {
                        min-width: 55px; /* Minimum width for the email column */
                        max-width: 55px;
                    }
                }
            }
            
            .user-list-header .title1, .user-list-item .content {
                flex: 1;
                text-align: left;
                min-width: 150px;
                // // max-width: 150px;
                overflow: hidden; /* Ensure content does not overflow */
                white-space: nowrap; /* Prevent text from wrapping */
                text-overflow: ellipsis; /* Add ellipsis for overflowing text */
                

                &.digs {
                    min-width: 170px;
                    max-width: 170px;

                    // &.content {
                    //     color: #6A6A6A;
                    //     font-family: "DM Sans";
                    //     font-size: 14px;
                    //     font-style: normal;
                    //     font-weight: 500;
                    //     line-height: normal;
                    //     letter-spacing: -0.14px;

                    // }
                    
                    &.weekly {
                        min-width: 60px;
                        max-width: 60px; 
                    }


                    &.long {
                        max-width: 360px;
                        padding-right: 12px;
                    }
                    &.duration_digs {
                        min-width: 86px;
                        max-width: 86px;

                        &.weekly {
                            min-width: 46px;
                            max-width: 46px; 
                        }
                    }
                }
            }
            
            .user-list-item {
                background: #fff;
                color: #6a6a6a;
                transition: height 0.3s ease-in-out;

                &.black {
                    background: #1f1f1c;
                    color: #f3f4f8;
                }

                &.digs:hover {
                    background: #ECECEC;
                }

                
            }
            
            .profile-picture {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover; /* Ensure the image fits within the container */
                flex-shrink: 0; /* Prevent the image from shrinking */

                &.hvr {
                    width: 180px;
                    height: 180px;
                }
            }            
        }

        .tables_digas {
            width: 180px;
            padding: 20px 0;
            background: #fff;
            margin-top: 14px;
            border-radius: 30px;
            margin-right: 10px;

            .header {
                padding: 0 12px;
                .title_digs {
                    color: #494949;
                    font-size: 20px;
                    font-weight: 700;
                }
            }


            .digs_title_container {
                max-height: calc(100vh - 234px);
                overflow-y: hidden;
                padding: 0 12px;
                padding-left: 0;
                &:hover {
                    overflow-y: auto;
                    padding-right: 7px;
                }

                p.title_table_digs {
                    margin: 4px 0;
                    color: #000;
                    font-family: "DM Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.14px;
                    cursor: pointer;

                    &.selected {
                        color: #00B17E;
                    }
                }
            }
        }
    }

    .add_user {
        width: 350px;
        padding-bottom: 16px;

        .options {
            border-radius: 6.425px;
            border: 0.803px solid var(--Gray-300, #D0D5DD);
            background: var(--Lightmode-Background, #F6F6F6);
            box-shadow: 0px 0.803px 1.606px 0px rgba(16, 24, 40, 0.05);
        }
    }

    .form-group-user {
        margin-bottom: 8px;
    
        &__label {
            display: block;
            font-size: 16px;
            margin-bottom: 4px;
        }
    
        &__input {
            width: 100%;
            padding: 10px;
            font-size: 16px;
            border: 2px solid #ccc;
            border-radius: 10px;
            outline: none;
    
            // &:focus {
            //     border-color: #000;
            // }
        }
    }

    .role {
        padding: 2px 4px;
        border-radius: 5px;
        display: inline-block;
        flex-shrink: 0; /* Prevent the role badge from shrinking */
        border: 1px solid;
        font-weight: bold;
        min-width: 150px;
        text-align: center;
    }
    
    .role.super-admin {
        background-color: rgba(255, 0, 0, 0.1);
        border-color: red;
        color: red;
    }
    
    .role.network-admin {
        background-color: rgba(0, 0, 255, 0.1);
        border-color: blue;
        color: blue;
    }
    
    .role.producer {
        background-color: rgba(0, 128, 0, 0.1);
        border-color: green;
        color: green;
    }
    
    .role.admin {
        background-color: rgba(255, 165, 0, 0.1);
        border-color: orange;
        color: orange;
    }
    
    .role.read-only {
        background-color: rgba(128, 128, 128, 0.1); /* Light gray background */
        border-color: gray; /* Gray border */
        color: gray; /* Gray text color */
    }

    .status {
        color: #6a6a6a;
        
        &.active {
            color: #0DB07D;
        }
        .dot {
            height: 14px;
            width: 14px;
            border-radius: 50%;
            background: #ADADAD;
    
            &.active {
                background: #0DB07D;
            }
        }

    }

    .userphoto_hover {
        width: 240px;
        height: 290px;
        padding: 12px 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, .5) !important;


        &.digs {
            width: 160px;
            height: 160px;
            padding: 0;
        }
    
    
        // border: #0DB07D 1px solid;
        border-radius: 6px;

        .text-hvr {
            color: #6a6a6a;

            &.black {
                color: #f3f4f8;
            }
        }
    }

    .no_image_bing {
        background: #D9D9D9;
    }

    .image_bing {
        box-sizing: border-box; // Ensure padding and border are included in width/height
        border: 2px solid transparent; // Set a transparent border to maintain size
    }

    .image_bing:hover {
        border: #1BB17D 2px solid;
    }


   

    
    
}



