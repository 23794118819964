.meldung_header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    // background: linear-gradient(180deg, #0A752F 0%, #7ED19B 108.87%);
    background: linear-gradient(90deg, #1A818D 5.84%, #1E6D91 44.26%, #283890 71.53%);
    // height: 48px;
    height: 38px;

    &.mob {
        height: 34px;
    }
    padding-left: 6px;
    padding-right: 12px;

    h3 {
        font-size: 20px;
        color: #fff;
        
        // &.profile {
        //     background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: transparent;
        // }
    }

    &.aus {
        background: linear-gradient(90deg, #0DB07D 6.57%, #08AC94 29.29%, #1A818D 76.68%);
    }

    &.detail {
        // background: #f6f6f6;
        background: linear-gradient(90deg, #293991 0%, #36368C 100%);
        color: $light-blue-1;
        font-size: 16px;

        &.black {
            background: #1F1F1C;
        }
    }

    .autoplay {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        line-height: normal;
    }
}

.meldung_content {
    height: calc(100vh - 239px);
    background: #f6f6f6;
    overflow: auto;
    padding-left:  6px;
    padding-right: 6px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: height 0.3s ease-in-out;

    &.hide-act {
        height: calc(100vh - 142px);

        &.mob {
            height: calc(32vh - 100px);
        }
    }

    &.mob {
        height: calc(32vh - 135px);
        transition: height 0.3s ease-in-out;
        margin-bottom: 4px;

        &.collapsed {
            height:54px;
            overflow: hidden;
        }
        &.expanded {
            height: calc(66vh - 135px);
        }
    }

    &.black {
        background: #1f1f1c;
    }

    p.number {
        width: 26px;
        margin-right: 2px;
        color: #000;

        &.black {
            color: #f3f4f8;
        }

        // display: flex;
        // justify-content: center;
        // align-items: center;
        // justify-content: space-around;
    }

    p.type-name {
        font-size: 16px;
        color: #000;

        &.black {
            color: #f3f4f8;
        }
    }

    .ausgaben_item {
        margin: 3px;
    }

    &_item {
        border: 1px solid #0DB07D;
        border-radius: 6px;
        overflow: hidden;

        .overlay {
            background-color: #969696;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }


        .red-mark {
            background-color: #FB142D;
            transition: width 0.3s ease;
            border-radius: 6px;

            &.w6 {
                width: 6px;
            }

            &.exc {
                background-color: #fff;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

            }
        }

        // .duration_meldungen {
        //     color:#fff;
        //     background-color: #43BE83;
        //     width: 52px;
        //     height: 20px;
        //     display: flex;
        //     align-items: center;
        // }

        &.empty {
            border: 1px solid #FD6D22;
        }

        .news_note {
            font-size: 10px;
            font-weight: 500;
            background: rgb(255, 165, 0, 0.5)
        }

        p.type-name {
            font-size: 16px;
            color: #000;
    
            &.black {
                color: #f3f4f8;
            }
        }

        p.number {
            width: 26px;
            margin-right: 2px;
            color: #000;
    
            &.black {
                color: #f3f4f8;
            }
        }

        .file_hours {
            font-size: 10px;
            color: #000;

            &.black {
                color: #f3f4f8;
            }

        }
    }

    &_footer {
        font-size: 10px;
        color: #010101;
        background-color: #EEF8FF;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        p.report {
            font-weight: 500;
            font-size: 10px;
            color: #000;

            &.black {
                color: #f3f4f8;
            }

        }

        p.repeat_hours {
            color: #000;

            &.black {
                color: #F3F4F8;
            }
        }

        // .one-line {
        //     width: 30vw;
        // }

        &.no_border {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.red {
            background: #FFEA89;
            p {
                color: #333;
            }
        }

    }

    .anchor {
        color: $white;
        // background-color: $light-blue-1;
        background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
        border-radius: 8px;
        // margin: 4px 0;
        margin-bottom: 4px;
    }

    &_header {
        .badge {
            font-size: 12px;
            color: white;
        }

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .optional_container {
            border: 1px solid #6a6a6a;
            color: #6a6a6a;
            border-radius: 3px;
            font-size: 12px;
        }

        

        
    }
}

.news_detail_content {
    padding: 0 6px;
    background: #f6f6f6;
    padding-top: 4px;

    &.mob {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding-bottom: 8px;
    }

    &.black {
        background: #1F1F1C;
    }
    

    .player {
        height: 220px;
        border-radius: 10px;

        &.mob {
            height: 128px;
        }

        transition: background 0.5s ease;

        #meter {
            width: 100%;
            height: 20px;
            background-color: transparent;
            border: 1px solid #fff;
            position: relative;
            margin-top: 4px;
            overflow: hidden;
            border-radius: 0;

            border-radius: 6px;

            &.mob {
                height: 8px;
            }

            
            
        }

        #mask {
            background: linear-gradient(90deg, 
                #007F00 0%,               /* 25% Lighter Dark Green */
                #00AA00 25%,              /* 25% Lighter Medium Green */
                #00CC00 50%,              /* 25% Lighter Light Green */
                #CCFF00 75%,              /* 20% Yellow */
                #FF0000 100%              /* 5% Red */
            );
            height: 100%;
            width: calc(100% - 2px);
            border-radius: 6px;
        }

        
        #meter #bar {
            width: 100%;
            height: 100%;

            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
            -webkit-transition: all .1s ease-in-out;
            -moz-transition: all .1s ease-in-out;
            -ms-transition: all .1s ease-in-out;
            -o-transition: all .1s ease-in-out;
            transition: all .1s ease-in-out;
            position: absolute;
            top: 0;
            right: 1px;
        }

        .wave-container {
            max-width: 100%;
            position: relative;
            border-radius: 4px;
            height: 50px;

            &.black {
                background-color: #2e2e2e;
            }
            &.mob {
                height: 25px;
            }
            
            
            
            #waveform {
                
                .flag {
                    background: #E65E6D;
                    padding: 1px 4px;
                    z-index: 5;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;

                    color: #FFF;

                    width: 46px;

                    font-size: 12px;
                    font-weight: 700;
                    transition: width 0.3s ease;
                    text-align: center;
                }

            }

            // #waveform ::part(hover-label):before {
            //     content: '';
            // }

            div.loadingWs {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;
            
            }

            
        }

        &_buttons {
            p#remaining-time {
                padding-right: 2px;
            }

            .volume {
                transition: width 0.4s ease; 
            }
        }

        &_title {
            border-radius: 7px;

            .mx1 {
                margin: 0 1px;
            
            }
        }
    }
}

.blueBg {
    background: #fff;
    border-radius: 6px;

    &.black {
        background: #272723;
    }
}

.blueBgAus {
    background: #f6f6f6;

    &.black {
        background: #2e2e2e;
    }
}


.grayBgNew {
    background: #f6f6f6;
    border-radius: 6px;

   

    &.black {
        background: #2e2e2e;
        color: #f6f6f6;
    }
}

.news_detail_list {
    height: calc(100vh - 462px);
    overflow: auto;
    padding-left: 6px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: height 0.3s ease-in-out;
    background: #f6f6f6;

    &.black {
        background: #1F1F1C;
    }
    &.mob {
        height: calc(32vh - 128px);
        // margin-bottom: 4px;

        border-radius: 0;
    }

    &.hide-act {
        height: calc(100vh - 367px);

        &.mob {
            height: calc(32vh - 100px);
        }
    }

    .abhoren {
        border-radius: 4px;
        background: rgba(247, 79, 93, 0.30);
        color: #FB142D;
        padding: 2px 4px;
    }

    .customer_count {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: #9496A1;

        color: #FFF;
        font-size: 12px;
        font-weight: 500;
    }

    .title_profile {
        &.black {
            color: #f3f4f8;
        }
    }  
}

.duration {
    color: #fff;
    font-weight: 500;
    // padding: 0 2px;
    border-radius: 2px;
    box-shadow: 0px 0px 2.105px 0px rgba(13, 13, 13, 0.49) inset;
    display: flex;
    align-items: center;
    height: 21px;
    justify-content: center;

    &.estimation_container {
        height: 100%;
    }

    &.profile {
        background: #2B3990;
    }

    &.meldungen {
        background: #43BE83;
    }
    &.orange {
        background: #f68026;
    }

    &.bgAI {
        background: linear-gradient(175deg, #1C819F 25.85%, #6AF0D9 113.64%);
    }

    &.soundbite {
        background: #2098C8;
    }

    &.soundbite_14 {
        background: #2098C8;
        font-size: 14px;
    }

    &.gray {
        background: #c0c0c0;
    }
    &.zero {
        background: #c0c0c0;
    }
    &.red {
        background: linear-gradient(179deg, #f5475c 32.57%, #FF3B51 99.14%);

    }

    &.yellow {
        background: #ffea89;
    }

    // 
}  



.aus_item {
    background-color: #fff;
    padding: 4px 2px;
    margin-bottom: 2px;
    border-radius: 8px;
    color: #3d3d3d;

    &.black {
        background: #2e2e2e;
        color: #f3f4f8;
    }


    transition: all 0.3s ease-in-out;

    &.selected {
        background-color:#0DB07D;
    }
    .computer_container {
        // width: 300px;
        width: 650px;
        padding: 1px;

        box-shadow: 0 0 10px rgba(0, 0, 0, .3);

        border-radius: 6px;

        &.mob {
            width: 100%;
            max-height: 50vh;
            overflow-y: auto;
            box-shadow: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .title {
            // color:;
            // color: #0469AE;
            font-size: 16px;
            font-weight: bolder;
        }

        color: #000;
        font-size: 11.284px;
        font-style: normal;
        font-weight: 500;

        &.black {
            color: #f3f4f8;
        }
    }
    .number {
        width: 40px;
        height: 40px;
        background: #f6f6f6;
        border-radius: 8px;
        font-size: 16px;

        &.black {
            background: #1F1F1C;;
        }


        .computer-name {
            font-size: 12px;
            color: #000;
            text-align: center;

            &.black {
                color: #f3f4f8;
            }
        }

        &.clock {
            flex: 2;
            margin: 0 4px;
        }
    }

    .tooltip-computer {
        padding: 1px;
    }

    

    
}

.customer {
    width: 500px;
    max-height: 85vh;
    min-height: 200px;
    // border-radius: 9px;
    padding: 15px 8px;
    padding-top: 0px;
    // padding-left: 13px;
    overflow: auto;

    &.mob {
        width: 100%;
        max-height: 50vh;
    }

    // &_container {
    //     max-height: 520px;
    //     overflow: hidden;

    //     &:hover {
    //         overflow-y: auto;
    //         padding-right: 1px;
    //     }
    // }

    &_item {
        padding: 6px 4px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
    
        &.blue {
            background: #f6f6f6;

            &.black {
                background: #2e2e2e;
                color: #f3f4f8;
            }
        }

        h3.black {
            color: #f3f4f8; 
        }
    }
}

.profile_newsplan {
    width: 700px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    padding: 10px;

    background: white;

    &.black {
        background: #272723;
    }

    &.mob {
        width: 100%;
        max-height: 50vh;
        overflow: auto;
        box-shadow: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    
    // height: 600px;

    p.title {
        font-size: 20px;
        color: #000;
        font-weight: 700;
        // padding: 0px 20px;
        margin: 16px 0;

        &.black {
            color: #f3f4f8;
        }
    }

    &_container {
        overflow: hidden;

        // max-height: 530px;
        // padding: 0 20px;
        // padding-right: 12px;

        // &:hover {
        //     overflow: auto;
        //     padding-right: 6px;
        // }
        
        p.desc {
            font-size: 12px;
            color: #000;
            font-weight: 400;
        }
    }
}

.news_download {
    width: 680px;
    border-radius: 12px;
    height: 90vh;
    background: #fff;
    display: flex;
    flex-direction: column;

    &.black {
        background: #272723;
        color: #f3f4f8;
    }

    padding: 20px;
    padding-top: 0;

    &_info {
        font-size: 16px;
        color: #000;
        font-weight: 500;

        &.black {
            color: #f3f4f8;
        }
    }

    &_links {
        overflow: auto;
        padding: 10px;
        flex: 1;

        .items {
            padding: 10px 12px;
            cursor: pointer;
            border-radius: 7px;
            background: #fff;

            &.black {
                background: #272723;
            }

            .megabyte {
                color: #fff;
                // background-color: #0469AE;
                background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
                font-size: 15px;
                padding: 0 8px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                // justify-content: center;
                padding: 0 6px;
                width: 70px;

                &.date {
                    justify-content: center;
                    width: 164px;
                }
            }
        }
    }

} 

.minimize-btn {
    bottom: 100px;
    z-index: 10;
    left: -10px;

    &.mob {
        bottom: 82px;
    }
}


.actualities {
    height: 103px;
    border-radius: 12px;
    background-color: #f6f6f6;

    &.mob {
        height: 80px;
    }


    &.black {
        background-color: #1F1F1C;
    }

    &_title {
        background: #2098C8;
        // background: linear-gradient(180deg, #007AFF 0.06%, #003166 155.71%);
        height: 100%;
        width: 35px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        position: relative;
        margin-right: 10px;

        p.toneTitle {
            color: #fff;
            transform: rotate(270deg);
            /* Optionally, adjust width and height if necessary */
            width: 90px;
            position: absolute;
            text-align: center;
        }

        
    }

    

    .sb_container {
        width: calc(100vw - 200px);
        overflow: hidden;
        padding: 4px 0;

        &.mob {
            width: calc(100vw - 100px);
            overflow: auto;
        }
        &:hover {
            overflow: auto;
            padding-bottom: 0;
        }
        
        .sb_item {
            flex-shrink: 0;
            padding: 4px 8px;
            width: 200px;
            // height: 78px;
            border-radius: 14px;
            background: #fff;
            // box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.13);
            margin-right: 10px;

            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.13);

            &.black {
                background: #2e2e2e;
            }

            &_info {
                font-size: 14px;
                margin-left: 11px;
                flex: 1;

                p.title {
                    width: 112px;

                    &.black {
                        color: #f3f4f8;
                    }
                }
                p.filename {
                    &.black {
                        color: #f3f4f8;
                    }
                }
            }
        }

        .upload_sb {
            margin-right: 10px;
            background: #fff;
            // padding: 8px;
            // padding-bottom: 2px;
            height: 40px;
            width: 40px;
            border-radius: 8px;     
            flex-shrink: 0; 

            &.black {
                background: #2e2e2e;
            }
        }


    }

}

.hover_soundbite {
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    width: 442px;
    padding: 18px;
    border-radius: 6px;

    &.mob {
        width: 100%;
        max-height: 50vh;
        overflow: auto;
        box-shadow: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &.tool {
            padding-bottom: 0;
        }
    }


    &.black {
        background: #272723;

    }

    p.title {
        color: #0469AE;
        font-size: 14px;
        font-weight: 700;
    }

    p.title1 {
        color: #1B1B1C;
        font-size: 16px;
        font-weight: 700;

        &.black {
            color: #f3f4f8;
        }
    }

    p.title2 {
        color: #000;
        font-size: 14px;
        font-weight: 500;
    }

    p.title3 {
        color: #000;
        font-size: 14px;
        font-weight: 400;
    }

    p.black {
        color: #f3f4f8;
    }
}

.traffic_table {
    border: 1px solid #0469AE;
    margin: 2px 0;
    border-radius: 4px;

    .text {
        border-left: 1px solid #0469AE;
        padding-left: 4px;

        &.red {
            border-left: 1px solid #ef4444;
        }
        &.white {
            border-left: 1px solid #fff;
        }
    }

    &.red {
        border: 1px solid #ef4444;
    }
    &.white {
        border: 1px solid #fff;
    }

}

.edit_modal {
    width: 800px;
    height: 420px;

    textarea {
        flex: 1 1;
        overflow-y: auto;
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        padding: 6px;
    }
}
.new_modal {
    width: 830px;
    height: 560px;

    .type-list {
        transition: height 0.3s ease-in-out;

    }

    textarea {
        flex: 1 1;
        overflow-y: auto;
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        padding: 6px;
        font-size: 22px;

        &.black {
            // background: #2e2e2e;
            // color: #f3f4f8;
            border: 2px solid #2e2e2e;
        }
    }

    input.headline_input {
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        padding: 0 4px;
        width: 50%;

        &:focus {
            outline: none;
        }
    }
}

.drag_over {
    background-color: #e3e3e3;
    color: #333;
    border-bottom: 6px;

    &_ex {
        background-color: #04a559;
        color: white;
        border-bottom-left-radius: 6px;
    }
    &_follow {
        background-color: #0767ae;
        color: white;
        border-bottom-right-radius: 6px;
    }
}

.username_container {
    // border: 1px solid #0469AE;
    background: #f3f3f3;
    border-radius: 6px;
    padding: 4px 10px;

    p {
        color: #000;
        // font-size: 16px;
        &.name {
            font-size: 16px;
        }
        &.pc {
            font-size: 12px;

            &.black {
                color: #f3f4f8;
            }
        }
    }
}

.table_voice_container {
    color: #000;
    &.black {
        color: #f3f4f8;
    }
}

.table_blue {
    background: #f6f6f6;
    border-radius: 6px;

    &.black {
        background: #2e2e2e;
    }
}

.record_modal {
    height: 100vh;
    width: 100%;
    // height: 70vh;
    border-radius: 6px;

    box-shadow: 0 0 10px rgba(0, 0, 0, .3);

    right: 0;
    background: white;
    z-index: 22;

    &.black {
        background: #272723;
        color: #f3f4f8;
    }

    .text_rec {
        color: #000;

        .header {
            // background: linear-gradient(188deg, #FFF 6.06%, #0864A4 292.33%);
            background: linear-gradient(90deg, #1A818D 2.08%, #1E6D91 57.97%, #283890 97.62%);
            border-top-left-radius: 6px;
            // margin-bottom: 14px;
            &_title {
                padding: 10px 16px;
                font-size: 20px;
                font-weight: 700;
                color: #fff;

                &.red {
                    color: #fff;
                }




            }

            &.red {
                background: linear-gradient(179deg, #CF2C2E 32.57%, #F97E7F 99.14%);
            }
        }

        .text_container {
            &.black {
                background: #272723;
                color: #f3f4f8;
            }
        }
        
        .type {
            padding: 0 16px;
            font-size: 22px;
            font-weight: 700;
        }

        
    }

    .text_recording {
        padding: 0 16px;
        font-size: 20px;
        font-weight: 300;
        overflow-y: auto;
        user-select: none;
        cursor: default;

        &.black {
            background: #272723;
            color: #f3f4f8;
        }

        
    }

    // textarea.edit_in_recording {
    //     font-size: 20px;
    //     font-weight: 300;
    //     overflow-y: auto;

    //     border: 1px solid #e3e3e3;
    //     margin-bottom: 12px;
    //     border-radius: 16px;
    // }

    .recording {
        // height: 438px;
        width: 100%;

        border-radius: 16px;
        background: #f6f6f6;
        padding: 16px;
        padding-bottom: 0.5rem;

        &.black {
            background: #1F1F1C;
            color: #f3f4f8;
        }

        .types_container {
            background: white;
            &.black {
                background: #272723;
                color: #f3f4f8;
            }
        }
        

        .mx1 {
            margin: 0.5px;
            font-size: 12px;
        }

        .mic_container {
            display: flex;
            height: 300px;
            
            .mic {
                width: 230px;
                height: 100%;
                border-radius: 6px;
                
                &_rec {
                    // background: #fff;
                    height: 100%;
                    width: 100%;
                    border-radius: 6px;

                    p {
                        color: #6a6a6a;

                        &.black {
                            // background: #272723;
                            color: #f3f4f8;
                        }
                    }
                }

                // .select_mic {
                  
                // }

                .two_buttons {
                    padding: 10px 0;
                    padding-bottom: 0;
                    width: 100%;

                    .border_play_rec {
                        border: 1px solid #066AAE;
                    }
                }
            }

            .prod_buttons_container {
                display: grid;
                grid-template-columns: 1fr 1fr; /* Creates two columns of equal width */
                gap: 8px;
                width: 190px;

                .pBtn {
                    width: 100%;
                    // height: 94px;
                    // border: 1px solid black;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 6px;
                    color: #F3F4F8;
                    border: 0.626px solid #FFF;
                }
            }
        }

        .buttons {
            height: 30px;
            margin: 10px 0;
        }

        .wave {
            margin: 0 10px;
            #mic,#result_mic,#sb_rec {
                background: white;
                border-radius: 10px;
                min-height: 101px;

                &.black {
                    background: #272723;
                    // color: #f3f4f8;
                }



            }
            .wave_rec_container {
                border-radius: 6px;
                padding: 10px;
                padding-top: 6px;
                
                &.blue {
                    background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
                }
                &.red {
                    background: linear-gradient(179deg, #CF2C2E 32.57%, #F97E7F 99.14%);
                }  
                
                &.gray {
                    background: #c0c0c0;
                }
                           
                
                p {
                    color: #fff;
                    font-size: 16px;
                    margin-bottom: 4px;
                }

                &.sb {
                    background: linear-gradient(265deg, #2B3890 4.1%, #2095C3 96.24%);
                }

                &.gray {
                    background: #c0c0c0;
                }

                div.loadingWs {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                
                }

                .virtual_sb {
                    border: 1px solid #fff;
                    padding: 0 4px;
                    border-radius: 6px;
                    font-weight: bolder;
                }
            }

            #sb_rec {
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    background: transparent; /* Optional: to make the overlay semi-transparent */
                    z-index: 90; /* Ensure it sits on top */
                    pointer-events: auto; /* Allows interaction below the overlay */
                }
                
                // #sb_rec .overlay {
                //     pointer-events: auto; /* Disables interaction below the overlay */
                // }
        }
        }


        .sound {
            height: 72px;
            padding: 6px;
            border-radius: 6px;
            // background: linear-gradient(205deg, #0469AE -85.35%, #3BA6F1 82.79%);
            // background: #f6f6f6;

            .timer {
                .gross {
                    color: #6a6a6a;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;

                    &.black {
                        // background: #272723;
                        color: #f3f4f8;
                    }
                }

                .number {
                    color: #6a6a6a;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    &.black {
                        // background: #272723;
                        color: #f3f4f8;
                    }
                }
            }

            &_meter {
                // height: 470px;
                .canvas_size {
                    width: 100%;
                    height: 26px;
                    // position: absolute;
                    // transform: rotate(90deg); /* Correct way to rotate */
                    // top: 0;
                    // left: 320px;
                    // border: 1px solid;

                    // &.left {
                    //     top: -30px;
                    // }

                    // &.zero {
                    //     height: 26px;
                    // }
                }
            }
        }

    }
}

.computer_name_modal {
    width: 600px;

    &.mob {
        width: 100%;
    }
}

.gradient-green {
    background: linear-gradient(white, white) padding-box,
                linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%) border-box;
    border-radius: 6px;
    border: 2px solid transparent;

    &.black {
        background: linear-gradient(#2e2e2e, #2e2e2e) padding-box,
                    linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%) border-box;
    }
}

.diagonal{
    white-space: nowrap;
    position: absolute;
    background: #FD6D22;
    min-width: 70px;
    transform: rotate(35deg) translate(-84%, 0);
    color: #fff;
    text-align: center;
    // text-transform: uppercase;
    font-size: 8px;
    top: 0;
    right: -145px;
    box-sizing: border-box;
}

.textarea-container {
    position: relative;
}
  
.highlighted-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 22px;
    padding: 6px;
    overflow-y: auto;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    padding: 6px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 1;
    pointer-events: none;
    font-family: Arial, sans-serif; /* Ensure the same font as textarea */

    &.in_recording {
        font-size: 20px;
    }
}

textarea.edit {
    position: relative;
    height: 100%;
    background: none;
    z-index: 2;
    width: 100%;
    height: 100%; /* Adjust as needed */
    color: transparent; /* Make the text in textarea transparent */
    caret-color: black; /* Keep the caret visible */
    &.black {
        caret-color: #08AC94;
    }
    font-family: Arial, sans-serif; /* Ensure the same font as highlighted text */

    &.in_recording {
        border-radius: 8px;
        padding: 6px;
        font-size: 20px;
        // color: red;
    }
}

.modal_copy {
    width: 1226px;

    &.narrow {
        width: 625px;
    }
    max-height: 80vh;
}

.file_upload {
    width: 50%;
    height: 50%;
    padding: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid #C3C3C3;
    border-radius: 5px;

    input#soundbiteName {
        border-radius: 5px;
        border: 1px solid #C3C3C3;
        // height: 10px;
        padding: 4px;
        outline: none;
        box-shadow: none;

        &.black {
            background: #1f1f1c;
            color: #f3f4f8;
        }
    }

    .info {
        color: #6a6a6a;
        user-select: none;
    }
}

$active-gradient: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);

.tab-container {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    // margin: 20px auto;
}

.tab {
    flex: 1;
    padding: 4px;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s;
    
    &:not(.active) {
        background: #f0f0f0;

        &.black {
            background: #2e2e2e;
            color: #f3f4f8;
        }
    }

    &.active {
        background: $active-gradient;
        color: white;
    }
}

.roscom {
    &_title {
        color: #6C6C6C;
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}



  