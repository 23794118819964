.story-overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;

  .nav {
    .title {
      color: #484848;
      font-family: "Forgotten Futurist";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 14px;
    }
  }

  .loading,
  .error {
    text-align: center;
    padding: 20px;
  }

  .header,
  .body {
    display: grid;
    grid-gap: 1px;
    // background-color: #e0e0e0;
  }

  .header {
    grid-template-columns: minmax(150px, 1fr) repeat(auto-fit, 1fr);
    background: #fff;
    font-weight: bold;
    margin-top: 16px;
  }

  .type-header,
  .hour-header {
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 100%;
    padding: 16px 10px;
    background: #fff;
    border: 2px solid #F1F1F1;
    box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
    text-align: center;

    color: #7F7F7F;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */

    &.blue {
      color: #0469AE;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      border-top-left-radius: 8px;
    }

    border-bottom: none;
    border-right: none;

    &.lastH {
      border-right: 2px solid #F1F1F1;
      border-top-right-radius: 8px;
    }
  }

  .story_container {
    padding: 20px;
  }

  .body {
    display: flex;
    flex-direction: column;
    height: 80vh;
    overflow: auto;

    .type-row {
      display: grid;
      grid-gap: 1px;
      grid-template-columns: minmax(150px, 1fr) repeat(auto-fit, 1fr);
      background-color: #fff;

      // &:nth-child(odd) {
      //   background-color: #f9f9f9;
      // }

      .type-name {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        padding: 10px;
        background: #fff;
        border: 2px solid #F1F1F1;
        box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);

        border-bottom: none;
        border-right: none;

        &.last {
          border-bottom: 2px solid #F1F1F1;
          border-bottom-left-radius: 8px;
        }
      }

      .story-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border: 2px solid #F1F1F1;
        box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
        background: #fff;
        word-break: break-all;

        color: #9A9A9A;
        text-align: center;
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        border-bottom: none;
        border-right: none;

        &.last {
          border-bottom: 2px solid #F1F1F1;
        }
        &.lastH {
          border-right: 2px solid #F1F1F1;
        }

        &.droppable {
          background: rgba(255, 203, 51, 0.25);
        }
      }
    }
  }
}


.clock_container {
  user-select: none;
  .clock {
    color: #6A6A6A;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .time_zone {
    color:  #0DB07D;
    text-align: center;
    font-family: "Forgotten Futurist";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
