nav.navbar {
    height: 86px;
    border-radius: 0px 0px 14px 14px;
    background: #FFF;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
    margin-bottom: 16px;
    padding: 0 16px;

    .burger {
        border-radius: 3.18px;
        background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

    .gradient-as-title {
        background: linear-gradient(107deg, #1BB17D 25.2%, #283890 74.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
        font-weight: bold;

        text-align: center;
        font-family: "Forgotten Futurist";
        font-size: 21.295px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    
    

    
}