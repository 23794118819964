.reusable-button {
    border-radius: 6px;
    transition: transform 0.2s ease;

    &.rounded {
        border-radius: 16px;
    }
}

.box_shadow {
    // box-shadow: 0px 0px 4px 0px rgba(13, 13, 13, 0.49) inset;
    font-size: 12px;
    height: 24px;
}

.w11 {
    width: 11px;
}

.w22 {
    width: 22px;
}

.w40 {
    width: 40px;
}

.w52 {
    width: 52px;
}
.w64 {
    width: 64px;
}
.w72 {
    width: 72px;
}

.w84 {
    width: 84px;
}
.w100 {
    width: 100px;
}

.w110 {
    width: 110px;
}
.w115 {
    width: 115px;
}

.w126 {
    width: 126px;
}
.w130{
    width: 130px;
}
.w145 {
    width: 145px;
}
.w146 {
    width: 146px;
}
.w150 {
    width: 150px;
}



.w170 {
    width: 170px;
}
.w220 {
    width: 220px;
}

.w500 {
    width: 600px;
}
.h380 {
    height: 380px;
}
.h22 {
    height: 22px;
}
.h37 {
    height: 37px;
}
.h70 {
    height: 70px;
}

.h130 {
    height: 150px;
}

.h90vh {
    max-height: 80vh;
}
