// src/Pagination.scss
.pagination {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.5rem;
    margin: 14px 0;
  
    &__button,
    &__number {
      background-color: #f0f0f0;
      border: none;
      padding: 2px 12px;
      cursor: pointer;
      transition: background-color 0.3s;
      border-radius: 4px;
  
      &:hover {
        background: #e0e0e0;
      }
  
      &:disabled {
        cursor: not-allowed;
        background: #cccccc;
      }

      user-select: none;
    }
  
    &__number.active {
        border-radius: 4px;
        background: linear-gradient(180deg, #1BB17D 0.06%, #283890 158.35%);
        color: white;
    }
}
  