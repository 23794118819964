.home_card {
    background-color: #f6f6f6;
    border-radius: 16px;
    // height: 210px;
    box-shadow: 0px 35.85px 9.709px 0px rgba(0, 0, 0, 0.00), 0px 23.153px 8.963px 0px rgba(0, 0, 0, 0.01), 0px 12.697px 7.469px 0px rgba(0, 0, 0, 0.05), 0px 5.975px 5.975px 0px rgba(0, 0, 0, 0.09), 0px 1.494px 2.988px 0px rgba(0, 0, 0, 0.10);
    &.black {
        background-color: #2e2e2e;
    }



    &:hover {
        h3 {
            background-color: $green-100;
            color: $green-300;
            // border: 1px solid $green-200;
        }
    }

    &_content {
        
        h3 {
            // background-color: $sky-50;
            background: linear-gradient(91deg, #1BB17D 31.32%, #283890 105.5%);
            padding: 10px 0;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            // color: $sky-300;
            color: white;
            // border: 1px solid $sky-200;
        }

        h4 {
            color: $light-blue-1;
        }

        .desc {
            color: #2e2e2e;

            &.black {
                color: #f3f4f8;
            }
        }

        .rounded-image {
            width: 60px; /* Adjust the size as needed */
            height: 60px; /* Ensure width and height are equal for a perfect circle */
            // border-radius: 50%;
            // object-fit: cover; /* Ensures the image covers the element's content box */
            border: 2px solid #18b17d;
            border-radius: 50%;
            overflow: hidden;
        }

        .label {
            left: 50%; /* Move the element to the middle of the parent */
            transform: translateX(-50%);
            bottom: -10px;
            font-size: 12px;
            color: white;
            padding: 1px 6px;
            border-radius: 2px;
            font-weight: bold;

            &.green {
                background-color: #18b17d;
            }
            &.blue {
                background: #024798;
            }
        }
    }
}
